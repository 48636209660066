/**
 * Возвращяет время в секундах в человеко понятном виде.
 *
 * DHMS - Days Hours Minuets Seconds
 * @param seconds - значение всекндах, для преобразования
 *
 * @return {string} - "1 день, 18:03:05"
 */
export const secondsToDHMS = (seconds) => {

    let date = getDHMSValuesObject(seconds);

    return (date.days.formatted + date.hours.formatted + date.minutes.formatted + date.seconds.formatted).replace(/,\s*$/, "");
}

/**
 * Преобразовывет секунды человеко-понятные данные.
 * Возвращает объект объектов типа {formatted: '2 дня, ', value: 2}
 *
 * DHMS - Days Hours Minuets Seconds
 * @param seconds - значение всекндах, для преобразования
 *
 * @return {days, hours, minutes, seconds}
 */
export const getDHMSValuesObject = (seconds) => {
    seconds = Number(seconds);

    let d = Math.floor(seconds / (3600*24));
    let h = Math.floor(seconds % (3600*24) / 3600);
    let m = Math.floor(seconds % 3600 / 60);
    let s = Math.floor(seconds % 60);

    let dDisplay;
    dDisplay = d === 1 ? d > 0 ? d + " день, " : "" : d > 1 && d < 5 ? d > 0 ? d + " дня, " : "" : d > 0 ? d + " дней, " : "";
    let hDisplay;
    hDisplay = h > 9 ? h + ':' : h < 10 ? '0' + h + ':' : '';
    let mDisplay;
    mDisplay = m > 9 ? m + ':' : m < 10 ? '0' + m + ':' : '';
    let sDisplay;
    sDisplay = s > 9 ? s + '' : s < 10 ? '0' + s + '' : '';

    return {
        days: {
            formatted: dDisplay,
            value: d
        },
        hours: {
            formatted: hDisplay,
            value: h
        },
        minutes: {
            formatted: mDisplay,
            value: m
        },
        seconds: {
            formatted: sDisplay,
            value: s
        }
    }
};